import { HiOutlineX } from "react-icons/hi";

const Opsi = ({ noOpsi, tpOpsi, point, txtOpsi, onClick }) => {
  return (
    <div className="relative flex flex-row gap-x-4 mb-2 bg-slate-50 shadow p-4 rounded-lg items-center">
      <button
        type="button"
        onClick={onClick}
        className="absolute top-0 right-0 bg-gray-500 rounded-full p-1 hover:bg-gray-400"
      >
        <HiOutlineX size={12} color="#FFFFFF" />
      </button>
      <div>
        <div className="px-3 py-0.5 ml-auto text-2xl font-sans font-bold tracking-wide text-blue-500 bg-blue-50 rounded-full">
          {noOpsi}
        </div>
      </div>
      {tpOpsi === "image" && (
        <div>
          <figure className="relative max-w-sm w-[40px] transition-all duration-300 cursor-pointer hover:shadow-xl hover:-translate-y-1">
            <a href={`${txtOpsi}`} target="_blank" rel="noreferrer">
              <img className="rounded-lg" src={`${txtOpsi}`} alt={txtOpsi} />
            </a>
          </figure>
        </div>
      )}
      <div className="flex-1">
        <div className="inline-flex flex-row gap-x-2">
          <div className="px-2 py-0.5 ml-auto text-xs font-sans font-bold tracking-wide text-red-500 bg-red-50 rounded-sm">
            {tpOpsi}
          </div>
          <div className="px-2 py-0.5 ml-auto text-xs font-sans font-bold tracking-wide text-green-500 bg-green-50 rounded-sm">
            Point: {point}
          </div>
        </div>
        <p className="mt-2 font-sans text-sm">{txtOpsi}</p>
      </div>
    </div>
  );
};

export default Opsi;
