import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

const Instruction = () => {
  return (
    <div>
      <Header
        titleHead="Outlet"
        icon={<HiOutlineQuestionMarkCircle size={22} color="#1d4ed8" />}
      />
      <Outlet />
    </div>
  );
};

export default Instruction;
