import React from "react";

import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const Soal = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/pemohon/soal/${id}`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                KODE
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                NAMA SOAL
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TGL. SELESAI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                NO. URUT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TS
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TJ
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                PT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                WP
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                WD
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                WT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                WS
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && (
              <>
                {data.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">{index + 1}</td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {row.kodeSoal}
                      </td>
                      <td className="px-2 py-2 border-r text-gray-900">
                        {row.namaSoal}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {row.tglPenyelesaian}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.noUrut}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.totalSoal}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.totalDijawab}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.point}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.waktuPengerjaan}s
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.waktuDitambahkan}s
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.waktuTerpakai}s
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {row.waktuSisa}s
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="p-4">
        <ul className="font-sans text-xs">
          <li>
            <span className="font-bold">TS</span>: TOTAL SOAL
          </li>
          <li>
            <span className="font-bold">TJ</span>: TOTAL SOAL DIJAWAB
          </li>
          <li>
            <span className="font-bold">PT</span>: TOTAL POINT
          </li>
          <li>
            <span className="font-bold">WP</span>: WAKTU PENGERJAAN SOAL
          </li>
          <li>
            <span className="font-bold">WD</span>: WAKTU DITAMBAHKAN
          </li>
          <li>
            <span className="font-bold">WT</span>: WAKTU TERPAKAI
          </li>
          <li>
            <span className="font-bold">WS</span>: WAKTU SISA
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Soal;
