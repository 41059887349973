import React from "react";

import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const Profile = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/pemohon/${id}`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="grid grid-cols-3 m-4 font-sans text-sm gap-y-2">
        <div className="col-span-1 text-gray-400">Nama Lengkap</div>
        <div className="col-span-2 font-bold">{data.namaLengkap}</div>

        <div className="col-start-1 col-span-1 text-gray-400">NIK</div>
        <div className="col-span-2">{data.nik}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Tempat Lahir</div>
        <div className="col-span-2">{data.tmpLahir}</div>

        <div className="col-start-1 col-span-1 text-gray-400">
          Tanggal Lahir
        </div>
        <div className="col-span-2">{data.tglLahir}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Nomor HP</div>
        <div className="col-span-2">{data.noHp}</div>

        <div className="col-start-1 col-span-1 text-gray-400">
          Jenis Kelamin
        </div>
        <div className="col-span-2">
          {data.jenKel === "L" ? "LAKI-LAKI" : "PEREMPUAN"}
        </div>

        <div className="col-start-1 col-span-1 text-gray-400">
          Pendidikan Terakhir
        </div>
        <div className="col-span-2">{data.pdTerakhir}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Alamat</div>
        <div className="col-span-2">{data.alamat}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Tanggal Tes</div>
        <div className="col-span-2">{data.tglTes}</div>
        <div className="col-start-1 col-span-1 text-gray-400">
          Tanggal Selesai
        </div>
        <div className="col-span-2">
          {data.stSelesai === "Y" ? data.tglSelesai : "-"}
        </div>
      </div>
    </div>
  );
};

export default Profile;
