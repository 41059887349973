import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineSave,
  HiOutlineArrowLeft,
  HiOutlinePlus,
  HiOutlineX,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestPut, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TextArea from "../../components/Form2/TextArea";
import Select from "../../components/Form2/Select";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const FormEdit = () => {
  const { id } = useParams();

  const [namaScoring, setNamaScoring] = useState("");
  const [deskripsi, setDeskripsi] = useState("");

  const [namaKategori, setNamaKategori] = useState("");
  const [nilai1, setNilai1] = useState(0);
  const [nilai2, setNilai2] = useState(1);
  const [kategoriNilai, setKategoriNilai] = useState([]);
  const [scoringId, setScoringId] = useState("");

  const itemTipe = [
    {
      name: "RENDAH",
      value: "RENDAH",
    },
    {
      name: "KURANG",
      value: "KURANG",
    },
    {
      name: "CUKUP",
      value: "CUKUP",
    },
    {
      name: "BAIK",
      value: "BAIK",
    },
    {
      name: "SANGAT BAIK",
      value: "SANGAT BAIK",
    },
  ];

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/scoring/${id}`,
    sendRequestGet
  );

  const { data: dataScoring, isLoading: isLoadingScoring } = useSWR(
    `${API_URL_TES}/administrator/scoring/findselect`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/scoring/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setNamaScoring(data.namaScoring);
      setDeskripsi(data.deskripsi);
      setKategoriNilai(data.kategoriNilai);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const addKategoriNilai = (e) => {
    e.preventDefault();
    if (namaKategori !== "" || nilai1 >= 0 || nilai2 >= 1) {
      const dataNilai = [];
      for (let index = parseInt(nilai1); index <= parseInt(nilai2); index++) {
        dataNilai.push({
          namaKategori: namaKategori,
          nilai: index,
        });
      }

      setKategoriNilai((current) => [...current, ...dataNilai]);
    } else {
      Swal.fire("Error!", "Kategori nilai harus di isi!", "warning");
    }
  };

  const delKategoriNilai = (index) => {
    setKategoriNilai((kategoriNilai) => {
      return kategoriNilai.filter((_, i) => i !== index);
    });
  };

  const delAll = (e) => {
    e.preventDefault();

    setKategoriNilai([]);
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      if (kategoriNilai.length > 0) {
        const result = await trigger({
          namaScoring: namaScoring,
          deskripsi: deskripsi,
          kategoriNilai: kategoriNilai,
          scoringId: scoringId,
        });

        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: result.message,
          confirmButtonText: "Oke",
          willClose: () => {
            navigate(-1);
          },
        });
      } else {
        Swal.fire(
          "Error!",
          "Silahkan tambahkan master scoring terlebih dahulu!",
          "warning"
        );
      }
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating || isLoadingScoring) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <div className="sm:col-span-3">
          <div className="sticky top-28">
            <div>
              <h2 className="font-sans text-lg font-bold text-indigo-700">
                Update Master Scoring
              </h2>
              <p className="text-sm font-sans text-gray-400">
                Perubahan data master scoring, isi informasi data dibawah ini.
              </p>
            </div>
            <form onSubmit={simpan}>
              <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-start-1 sm:col-span-4">
                  <InputText
                    label="Nama Scoring"
                    name="namaScoring"
                    val={namaScoring}
                    set={setNamaScoring}
                    placeholder="Masukan nama scoring"
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    Isi nama scoring *ex: Logika
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <Select
                    label="Master Scoring"
                    name="scoringId"
                    val={scoringId}
                    set={setScoringId}
                    item={dataScoring}
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    silahkan pilih scoring master sub
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <TextArea
                    label="Deskripsi"
                    name="deskripsi"
                    val={deskripsi}
                    set={setDeskripsi}
                    rows={3}
                    placeholder="Silahkan isi deskripsi..."
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    Deskripsi untuk nama scoring
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <h2 className="font-bold font-sans text-black mb-4">
                    Kategori Nilai
                  </h2>

                  <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-300">
                    <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
                      <div className="sm:col-start-1 sm:col-span-1">
                        <Select
                          label="Kategori"
                          name="namaKategori"
                          val={namaKategori}
                          set={setNamaKategori}
                          item={itemTipe}
                        />
                        <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                          silahkan pilih kategori nilai
                        </p>
                      </div>
                      <div className="sm:col-span-1">
                        <InputText
                          label="Nilai 1"
                          name="nilai1"
                          val={nilai1}
                          set={setNilai1}
                          placeholder="0"
                        />
                      </div>
                      <div className="sm:col-span-1">
                        <InputText
                          label="Nilai 2"
                          name="nilai2"
                          val={nilai2}
                          set={setNilai2}
                          placeholder="0"
                        />
                      </div>

                      <div className="sm:col-start-1 sm:col-span-3">
                        <button
                          type="button"
                          onClick={addKategoriNilai}
                          className="text-white mt-2 bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                        >
                          <span className="mr-2">
                            <HiOutlinePlus size={18} />
                          </span>
                          <span>Tambah Nilai</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 flex items-center gap-x-2">
                <button
                  type="button"
                  className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                  onClick={() => navigate(-1)}
                >
                  <span className="mr-2">
                    <HiOutlineArrowLeft size={18} />
                  </span>
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  disabled={isMutating}
                  className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50 focus:outline-none focus:ring-[#3b5998]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                >
                  <span className="mr-2">
                    <HiOutlineSave />
                  </span>
                  <span>Update Scoring</span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div>
            <h2 className="font-sans text-lg font-bold text-indigo-700">
              Kategori Nilai
            </h2>
            <p className="text-sm font-sans text-gray-400">
              Kategori nilai yang sudah di input
            </p>
          </div>
          <button
            type="button"
            disabled={isMutating}
            onClick={delAll}
            className="text-white bg-[#e11d48] hover:bg-[#e11d48]/90 active::bg-[#e11d48]/50  focus:outline-none focus:ring-[#e11d48]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md my-3"
          >
            <span className="mr-2">
              <HiOutlineX size={18} />
            </span>
            <span>Hapus Semua Nilai</span>
          </button>

          <div className="grid grid-cols-4 gap-3">
            {kategoriNilai.length > 0 ? (
              kategoriNilai.map((item, index) => (
                <div key={`nilai-${index}`} className="col-span-2">
                  <div className="relative mb-2 bg-slate-50 shadow p-4 rounded-lg items-center">
                    <button
                      type="button"
                      onClick={() => delKategoriNilai(index)}
                      className="absolute top-0 right-0 bg-gray-500 rounded-full p-1 hover:bg-gray-400"
                    >
                      <HiOutlineX size={12} color="#FFFFFF" />
                    </button>
                    <p className="font-sans text-sm text-black">
                      Nama Kategori:{" "}
                      <span className="font-bold">{item.namaKategori}</span>
                    </p>
                    <p className="font-sans text-sm text-gray-500">
                      Nilai: {item.nilai}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="sm:col-span-3">
                <p className="font-sans text-sm text-gray-400 text-center mt-4">
                  Tidak master scoring yang ditambahkan...
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEdit;
