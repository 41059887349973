import imgNotFound from "../../assets/images/5274708.jpg";

const ErrorAccess = () => {
  return (
    <div className="flex items-center justify-center mx-10">
      <div className="flex flex-col items-center">
        <img src={imgNotFound} className="w-80" alt="illustration-error" />
        <div className="text-center">
          <p className="text-4xl text-black font-sans md:text-6xl lg:text-8xl">
            Can't Access, ERROR!
          </p>
          <p className="text-lg font-sans mb-6 text-gray-500">
            Halaman ini tidak bisa Anda akses, silahkan lapor ke Admin
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorAccess;
