import React from "react";
import { HiOutlineX } from "react-icons/hi";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import { API_URL_TES } from "../../config";
import LoadingGalley from "../Gallery/LoadingGalley";

const MasterSoal = ({ mastersoalId, instructionId, noUrut, onClick }) => {
  const { data, isLoading } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/${mastersoalId}`,
    sendRequestGet
  );

  const {
    data: dataInstruction,
    isLoading: isLoadingInstruction,
    error: errorInstruction,
  } = useSWR(
    `${API_URL_TES}/administrator/instruction/${
      instructionId !== "" ? instructionId : null
    }`,
    sendRequestGet
  );

  if (isLoading || isLoadingInstruction) return <LoadingGalley />;

  return (
    <div className="relative flex flex-row gap-x-4 mb-2 bg-slate-50 shadow p-4 rounded-lg items-center">
      <button
        type="button"
        onClick={onClick}
        className="absolute top-0 right-0 bg-gray-500 rounded-full p-1 hover:bg-gray-400"
      >
        <HiOutlineX size={12} color="#FFFFFF" />
      </button>
      <div>
        <div className="px-3 py-0.5 ml-auto text-2xl font-sans font-bold tracking-wide text-blue-500 bg-blue-50 rounded-full">
          {noUrut}
        </div>
      </div>
      <div className="flex-1">
        <div className="inline-flex flex-row gap-x-2">
          <div className="px-2 py-0.5 ml-auto text-xs font-sans font-bold tracking-wide text-red-500 bg-red-50 rounded-sm">
            {data.kodeSoal}
          </div>
          <div className="px-2 py-0.5 ml-auto text-xs font-sans font-bold tracking-wide text-blue-500 bg-blue-50 rounded-sm">
            {data.questions.length} Quest
          </div>
        </div>
        <p className="mt-2 font-sans text-sm font-bold">{data.namaSoal}</p>
        <p className="font-sans text-xs">{data.keterangan}</p>
        <div className="px-2 py-0.5 ml-auto text-xs font-sans font-bold tracking-wide text-gray-500 bg-gray-100 rounded-sm mt-1">
          W. Pengerjaan:{data.waktuPengerjaan}s (
          {Math.floor(data.waktuPengerjaan / 60)}m)
        </div>

        {!errorInstruction && dataInstruction && (
          <div className="px-2 py-0.5 ml-auto text-xs font-sans font-light tracking-wide text-emerald-500 bg-emerald-100 rounded-sm mt-1">
            Instruction: {dataInstruction.namaInstruction} (
            {dataInstruction.totalWaktu}s)
          </div>
        )}
      </div>
    </div>
  );
};

export default MasterSoal;
