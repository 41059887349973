import React from "react";
import ReactPaginate from "react-paginate";

const Paging = ({
  totalDocs,
  page,
  totalPages,
  limit,
  setLimit,
  changePage,
}) => {
  return (
    <div className="flex flex-row items-center justify-between px-2">
      <div>
        <span className="text-sm font-sans">
          Total Rows: {totalDocs} Page: {totalDocs ? page + 1 : 0} of{" "}
          {totalPages}
        </span>
      </div>
      <div>
        <select
          id="showData"
          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm font-sans rounded-lg focus:ring-blue-500 focus:border-blue-500 py-2"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={parseInt(totalPages)}
          forcePage={page}
          pageRangeDisplayed={5}
          onPageChange={changePage}
          renderOnZeroPageCount={null}
          containerClassName={
            "inline-flex items-center -space-x-px text-sm font-sans"
          }
          pageLinkClassName={
            "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          }
          previousLinkClassName={
            "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
          }
          nextLinkClassName={
            "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
          }
          activeLinkClassName={
            "z-10 px-3 py-2 leading-tight bg-indigo-200 text-indigo-500 cursor-auto font-bold border border-indigo-300 hover:bg-indigo-200 hover:text-indigo-500"
          }
          disabledLinkClassName={
            "z-10 px-3 py-2 leading-tight text-indigo-500 border border-indigo-300 cursor-auto bg-indigo-200 hover:bg-indigo-200 hover:text-indigo-500"
          }
        />
      </nav>
    </div>
  );
};

export default Paging;
