import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";

import { sendRequestGet } from "../../swr";
import useSWR from "swr";

const CetakHasil = () => {
  const { pendidikan, limit, katSim } = useParams();

  const navigate = useNavigate();

  const { data } = useSWR(
    `${API_URL_TES}/administrator/rekapsoal?pdTerakhir=${pendidikan}&limit=${limit}&katSim=${katSim}`,
    sendRequestGet
  );

  console.log(katSim);

  useEffect(() => {
    // get refresh token
    checkToken();
    // findData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
        <div className="">
          <div className="sticky top-28">
            <div>
              <h2 className="font-sans text-lg font-bold text-indigo-700">
                Cetak Hasil Tes Pemohon
              </h2>
              <p className="text-sm font-sans text-gray-400">
                Pilih sesuai filter untuk mencetak laporan pemohon!
              </p>
            </div>

            <div className="flex p-2 overflow-x-auto">
              <table className="min-w-max font-sans text-sm text-left text-gray-500 border-b">
                <thead className="border-b border-t text-gray-600 bg-white">
                  <tr>
                    <th rowSpan="2" scope="col" className="p-2 w-[15px] border">
                      NO
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      NIK
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      NAMA
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      TEMPAT LAHIR
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      TANGGAL LAHIR
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      KATEGORI SIM
                    </th>
                    <th
                      rowSpan="2"
                      scope="col"
                      className="px-2 py-3 w-[200px] border"
                    >
                      JENIS SIM
                    </th>
                    <th colSpan="10" className="text-center w-[600px] border">
                      {katSim === "A" && "MENCARI URUTAN GAMBAR"}
                      {(katSim === "B" || katSim === "C") && "PENCOCOKAN WARNA"}
                    </th>
                    <th colSpan="10" className="text-center w-[600px] border">
                      {(katSim === "A" || katSim === "B") &&
                        "MENCARI POLA GAMBAR"}
                      {katSim === "C" && "MELENGKAPI GAMBAR"}
                    </th>
                    <th colSpan="20" className="text-center w-[1000px] border">
                      MENCARI HURUF DAN ANGKA YANG HILANG
                    </th>
                    <th colSpan="1" className="text-center w-[80px] border">
                      MENEMUKAN HURUF / SIMBOL
                    </th>
                    <th colSpan="100" className="text-center w-[5000px] border">
                      {"PENJUMLAHAN"}
                    </th>
                    <th colSpan="28" className="text-center w-[1000px] border">
                      {"PENILAIAN DIRI"}
                    </th>
                  </tr>
                  <tr>
                    {Array.from({ length: 10 }).map((_, i) => (
                      <th
                        key={`visual-${i}`}
                        className="text-center w-[80px] border"
                      >
                        {i + 1}
                      </th>
                    ))}
                    {Array.from({ length: 10 }).map((_, i) => (
                      <th
                        key={`logika-${i}`}
                        className="text-center w-[80px] border"
                      >
                        {i + 1}
                      </th>
                    ))}

                    {Array.from({ length: 20 }).map((_, i) => (
                      <th
                        key={`kecermatan-${i}`}
                        className="text-center w-[80px] border"
                      >
                        {i + 1}
                      </th>
                    ))}

                    {Array.from({ length: 1 }).map((_, i) => (
                      <th
                        key={`konsentrasi-${i}`}
                        className="text-center w-[80px] border"
                      >
                        {i + 1}
                      </th>
                    ))}

                    {Array.from({ length: 100 }).map((_, i) => (
                      <th
                        key={`data-${i}`}
                        className="text-center w-[60px] border"
                      >
                        {i + 1}
                      </th>
                    ))}
                    {Array.from({ length: 28 }).map((_, i) => (
                      <th
                        key={`kepribadian-${i}`}
                        className="text-center w-[80px] border"
                      >
                        {i + 1}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((val, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50 border"
                      >
                        <td className="p-2">{index + 1}</td>
                        <td className="px-2 py-2  text-gray-900 border">
                          '{val.nik}
                        </td>
                        <td className="px-2 py-2  text-gray-900 border">
                          {val.namaLengkap}
                        </td>
                        <td className="px-2 py-2  text-gray-900 border">
                          {val.tmpLahir}
                        </td>
                        <td className="px-2 py-2 text-center  text-gray-900 border">
                          {val.tglLahir}
                        </td>
                        <td className="px-2 py-2 text-center  text-gray-900 border">
                          {val.katSim}
                        </td>
                        <td className="px-2 py-2  text-gray-900 border">
                          <span className="bg-green-300 rounded px-2 py-1">
                            {val.jenSim}
                          </span>
                        </td>
                        {(katSim === "A" || katSim === "C") && (
                          <>
                            {/* VISUAL SPASIAL */}
                            {val.soal[0].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* LOGIKA */}
                            {val.soal[1].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* KECERMATAN */}
                            {val.soal[2].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* KONSENTRASI */}
                            {val.soal[3].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab > 10 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR {item.nilaiJawab}
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR {item.nilaiJawab}
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* DAYA TAHAN */}
                            {val.soal[4].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[5].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[6].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[7].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[8].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[9].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[10].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[11].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[12].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[13].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {/* KEPRIBADIAN */}
                            {val.soal[14].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[50px] border"
                              >
                                {item.nilaiJawab > 3 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    NILAI {item.nilaiJawab}
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    NILAI {item.nilaiJawab}
                                  </span>
                                )}
                              </td>
                            ))}
                          </>
                        )}

                        {katSim === "B" && (
                          <>
                            {/* VISUAL SPASIAL */}
                            {val.soal[0].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* LOGIKA */}
                            {val.soal[1].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* KECERMATAN */}
                            {val.soal[2].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* KONSENTRASI */}
                            {val.soal[3].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[4].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}

                            {/* DAYA TAHAN */}
                            {val.soal[5].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[6].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[7].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[8].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[9].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[10].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[11].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[12].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[13].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {val.soal[14].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[80px] border"
                              >
                                {item.nilaiJawab === 1 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    BENAR
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    SALAH
                                  </span>
                                )}
                              </td>
                            ))}
                            {/* KEPRIBADIAN */}
                            {val.soal[15].nilai.map((item, idx) => (
                              <td
                                key={`visual-${index}-${idx}`}
                                className="text-center w-[50px] border"
                              >
                                {item.nilaiJawab > 3 ? (
                                  <span className="bg-green-300 font-semibold text-xs px-2 py-1 rounded">
                                    NILAI {item.nilaiJawab}
                                  </span>
                                ) : (
                                  <span className="bg-red-300 font-semibold text-xs px-2 py-1 rounded">
                                    NILAI {item.nilaiJawab}
                                  </span>
                                )}
                              </td>
                            ))}
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CetakHasil;
