import React from "react";
import { Outlet } from "react-router-dom";

import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const RootSoal = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/${id}`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="fixed bg-white pb-4 w-[calc(100%-16rem)] z-40 shadow-lg top-[94px]">
        <div className="mx-10 pt-4">
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <h2 className="font-sans font-bold text-lg text-black">
                Kelola Soal: {data.namaSoal} ({data.kodeSoal})
              </h2>
              <p className="text-sm font-sans text-gray-400">
                {data.keterangan}
              </p>
            </div>
            <div className="text-center">
              <p className="font-sans text-sm text-gray-400">TOTAL SOAL</p>
              <p className="font-sans text-2xl text-blue-700 font-bold">
                {data.questions.length}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[75px] py-6 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default RootSoal;
