import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL_AUTH } from "../../config";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import WarningAlert from "../../components/Alert/WarningAlert";
import InputText from "../../components/Form/InputText";
import InputPassword from "../../components/Form/InputPassword";

import useSWRMutation from "swr/mutation";
import { sendRequestPost } from "../../swr";

import bappenda from "../../assets/images/psisimku.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_AUTH}/auth`,
    sendRequestPost
  );

  const navigate = useNavigate();

  const actLogin = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({ username: username, password: password });

      localStorage.setItem("token", result.token);
      setIsAlert(false);
      navigate("/");
    } catch (e) {
      if (e.status > 400) {
        setErrorMessage(e.info.message);
        setIsAlert(true);
      } else {
        setErrorMessage("Error silahkan ulangi kembali!");
        setIsAlert(true);
      }
    }
  };

  return (
    <section className="bg-gradient-to-b from-teal-300 to-teal-600">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white border rounded-2xl shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-2 md:space-y-3 sm:p-8">
            <div className="flex flex-col items-center">
              <img src={bappenda} className="w-[180px]" alt="logo" />
              <h2 className="text-lg font-sans font-bold">PSISIMku</h2>
            </div>
            {isAlert && (
              <>
                <WarningAlert description={errorMessage} />
              </>
            )}

            <form onSubmit={actLogin}>
              <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                <div className="col-start-1 md:col-span-6 col-span-6">
                  <InputText
                    label="Username"
                    name="username"
                    set={setUsername}
                    val={username}
                  />
                </div>

                <div className="col-start-1 md:col-span-6 col-span-6">
                  <InputPassword
                    label="Password"
                    name="password"
                    set={setPassword}
                    val={password}
                  />
                </div>
              </div>
              {!isMutating ? (
                <>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-[#2563eb]/90 focus:ring-4 focus:outline-none focus:ring-primary-300 font-sans rounded-lg text-sm px-5 py-3 text-center shadow-lg"
                  >
                    Login
                  </button>
                </>
              ) : (
                <ButtonLoading />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
