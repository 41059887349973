import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { HiOutlineUserCircle } from "react-icons/hi";

const Pemohon = () => {
  return (
    <div>
      <Header
        titleHead="Pemohon"
        icon={<HiOutlineUserCircle size={22} color="#1d4ed8" />}
      />
      <Outlet />
    </div>
  );
};

export default Pemohon;
