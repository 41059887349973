import Root from "./root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import JadwalKonseling from "../pages/JadwalKonseling";
import TableJadwalKonseling from "../pages/JadwalKonseling/Table";
import FormAddJadwalKonseling from "../pages/JadwalKonseling/FormAdd";
import FormEditJadwalKonseling from "../pages/JadwalKonseling/FormEdit";

import User from "../pages/User";
import TableUser from "../pages/User/Table";
import FormAddUser from "../pages/User/FormAdd";
import FormEditUser from "../pages/User/FormEdit";
import FormPassword from "../pages/User/FormPassword";

import Outlets from "../pages/Outlets";
import TableOutlet from "../pages/Outlets/Table";
import FormAddOutlet from "../pages/Outlets/FormAdd";
import FormEditOutlet from "../pages/Outlets/FormEdit";

import MasterSoal from "../pages/MasterSoal";
import TableMasterSoal from "../pages/MasterSoal/Table";
import FormAddMasterSoal from "../pages/MasterSoal/FormAdd";
import FormEditMasterSoal from "../pages/MasterSoal/FormEdit";
import RootSoal from "../pages/MasterSoal/RootSoal";
import TableSoal from "../pages/MasterSoal/TableSoal";
import FormAddSoal from "../pages/MasterSoal/FormAddSoal";
import FormEditSoal from "../pages/MasterSoal/FormEditSoal";

import SoalActive from "../pages/SoalActive";
import TableSoalActive from "../pages/SoalActive/Table";
import FormAddSoalActive from "../pages/SoalActive/FormAdd";
import FormEditSoalActive from "../pages/SoalActive/FormEdit";

import Scoring from "../pages/Scoring";
import TableScoring from "../pages/Scoring/Table";
import FormAddScoring from "../pages/Scoring/FormAdd";
import FormEditScoring from "../pages/Scoring/FormEdit";

import Pemohon from "../pages/Pemohon";
import TablePemohon from "../pages/Pemohon/Table";
import RootPemohon from "../pages/Pemohon/RootPemohon";
import Profile from "../pages/Pemohon/Profile";
import ScoringPemohon from "../pages/Pemohon/Scoring";
import SoalPemohon from "../pages/Pemohon/Soal";
import Cetak from "../pages/Pemohon/Cetak";

import Instruction from "../pages/Instruction";
import TableInstruction from "../pages/Instruction/Table";
import FormAddInstruction from "../pages/Instruction/FormAdd";
import FormEditInstruction from "../pages/Instruction/FormEdit";

import ErrorPage from "../pages/ErrorPage";
import CetakHasil from "../pages/Pemohon/CetakHasil";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "jadwal",
        element: <JadwalKonseling />,
        breadcrumb: "Jadwal Konseling",
        children: [
          {
            index: true,
            element: <TableJadwalKonseling />,
            breadcrumb: "Jadwal Konseling",
          },
          {
            path: "add",
            element: <FormAddJadwalKonseling />,
            breadcrumb: "Tambah Konseling",
          },
          {
            path: ":id",
            element: <FormEditJadwalKonseling />,
            breadcrumb: "Edit Konseling",
          },
        ],
      },
      {
        path: "user",
        element: <User />,
        breadcrumb: "User",
        children: [
          { index: true, element: <TableUser />, breadcrumb: "User" },
          {
            path: "add",
            element: <FormAddUser />,
            breadcrumb: "Tambah User",
          },
          {
            path: ":id",
            element: <FormEditUser />,
            breadcrumb: "Edit User",
          },
          {
            path: "password/:id",
            element: <FormPassword />,
            breadcrumb: "Edit Password",
          },
        ],
      },
      {
        path: "outlet",
        element: <Outlets />,
        breadcrumb: "Outlet",
        children: [
          { index: true, element: <TableOutlet />, breadcrumb: "Outlet" },
          {
            path: "add",
            element: <FormAddOutlet />,
            breadcrumb: "Tambah Outlet",
          },
          {
            path: ":id",
            element: <FormEditOutlet />,
            breadcrumb: "Edit Outlet",
          },
        ],
      },
      {
        path: "mastersoal",
        element: <MasterSoal />,
        breadcrumb: "Master Soal",
        children: [
          {
            index: true,
            element: <TableMasterSoal />,
            breadcrumb: "Master Soal",
          },
          {
            path: "add",
            element: <FormAddMasterSoal />,
            breadcrumb: "Tambah Soal",
          },
          {
            path: ":id",
            element: <FormEditMasterSoal />,
            breadcrumb: "Edit Soal",
          },
          {
            path: ":id/questions",
            element: <RootSoal />,
            breadcrumb: "Kelola Soal",
            children: [
              {
                index: true,
                element: <TableSoal />,
                breadcrumb: "Kelola Soal",
              },
              {
                path: "add",
                element: <FormAddSoal />,
                breadcrumb: "Tambah Question",
              },
              {
                path: ":idQuestion",
                element: <FormEditSoal />,
                breadcrumb: "Edit Question",
              },
            ],
          },
        ],
      },
      {
        path: "soalactive",
        element: <SoalActive />,
        breadcrumb: "Soal Aktif",
        children: [
          {
            index: true,
            element: <TableSoalActive />,
            breadcrumb: "Soal Aktif",
          },
          {
            path: "add",
            element: <FormAddSoalActive />,
            breadcrumb: "Tambah Soal Aktif",
          },
          {
            path: ":id",
            element: <FormEditSoalActive />,
            breadcrumb: "Edit Soal Aktif",
          },
        ],
      },
      {
        path: "scoring",
        element: <Scoring />,
        breadcrumb: "Master Scoring",
        children: [
          {
            index: true,
            element: <TableScoring />,
            breadcrumb: "Master Scoring",
          },
          {
            path: "add",
            element: <FormAddScoring />,
            breadcrumb: "Tambah Master Scoring",
          },
          {
            path: ":id",
            element: <FormEditScoring />,
            breadcrumb: "Edit Master Scoring",
          },
        ],
      },
      {
        path: "pemohon",
        element: <Pemohon />,
        breadcrumb: "Pemohon",
        children: [
          {
            index: true,
            element: <TablePemohon />,
            breadcrumb: "Pemohon",
          },
          {
            path: ":id/detail",
            element: <RootPemohon />,
            breadcrumb: "Detail Pemohon",
            children: [
              {
                path: "profile",
                element: <Profile />,
                breadcrumb: "Profile",
              },
              {
                path: "scoring",
                element: <ScoringPemohon />,
                breadcrumb: "Scoring",
              },
              {
                path: "docsoal",
                element: <SoalPemohon />,
                breadcrumb: "Dokumen Soal",
              },
            ],
          },
          {
            path: "cetak",
            element: <Cetak />,
            breadcrumb: "Cetak Data Pemohon",
          },
          {
            path: "cetak/:pendidikan/:limit/:katSim",
            element: <CetakHasil />,
            breadcrumb: "Cetak Data Pemohon",
          },
        ],
      },
      {
        path: "instruction",
        element: <Instruction />,
        breadcrumb: "Instruksi",
        children: [
          {
            index: true,
            element: <TableInstruction />,
            breadcrumb: "Instruksi",
          },
          {
            path: "add",
            element: <FormAddInstruction />,
            breadcrumb: "Tambah Instruksi",
          },
          {
            path: ":id",
            element: <FormEditInstruction />,
            breadcrumb: "Edit Instruksi",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
