import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { HiOutlineBookOpen } from "react-icons/hi";

const MasterSoal = () => {
  return (
    <>
      <Header
        titleHead="Master Soal"
        icon={<HiOutlineBookOpen size={22} color="#1d4ed8" />}
      />
      <Outlet />
    </>
  );
};

export default MasterSoal;
