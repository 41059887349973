import React from "react";

const InputText = ({ label, name, set, val, placeholder }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block font-sans text-sm leading-6 text-black"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          id={name}
          name={name}
          type="text"
          autoComplete="off"
          value={val}
          onChange={(e) => set(e.target.value)}
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 focus:bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-sans"
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

export default InputText;
