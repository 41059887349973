import React, { useRef } from "react";
import Swal from "sweetalert2";

import { API_URL_TES, API_UPLOAD } from "../../config/index";
import { HiOutlineUpload, HiOutlineX } from "react-icons/hi";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  sendRequestPostWithToken,
  sendRequestGet,
  sendRequesPostImageWithToken,
  sendRequestDelete,
} from "../../swr";
import LoadingGalley from "./LoadingGalley";

const Gallery = ({ mastersoalId }) => {
  const inputFile = useRef(null);

  const {
    data: dataImage,
    mutate: mutateImage,
    isLoading: loadingImage,
  } = useSWR(
    `${API_URL_TES}/administrator/gallery/mastersoal/${mastersoalId}`,
    sendRequestGet
  );

  const { trigger: uploadImage, isMutating: isMutatingUpload } = useSWRMutation(
    `${API_UPLOAD}/gallery`,
    sendRequesPostImageWithToken
  );

  const { trigger: saveImage, isMutating: isMutatingSaveImage } =
    useSWRMutation(
      `${API_URL_TES}/administrator/gallery`,
      sendRequestPostWithToken
    );

  const { trigger: delImage, isMutating: isMutatingDelImage } = useSWRMutation(
    `${API_URL_TES}/administrator/gallery`,
    sendRequestDelete
  );

  const deleteImage = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await delImage({ id: id });

          mutateImage();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const onButtonUpload = () => {
    inputFile.current.click();
  };

  const handleChange = async (e) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", new Date().getTime());
      formData.append("folder", "psisimku");

      try {
        const result = await uploadImage(formData);

        if (result) {
          const saveImageRes = await saveImage({
            fileName: result.fileName,
            mastersoalId: mastersoalId,
          });

          mutateImage();

          Swal.fire({
            icon: "success",
            title: "Sukses!",
            text: saveImageRes.message,
            confirmButtonText: "Oke",
          });
        }
      } catch (error) {
        if (e.status > 400) {
          Swal.fire("Error!", e.info.message, "error");
        } else {
          Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
        }
      }
    }
  };

  if (
    loadingImage ||
    isMutatingUpload ||
    isMutatingSaveImage ||
    isMutatingDelImage
  )
    return <LoadingGalley />;

  return (
    <>
      <div>
        <h2 className="font-sans text-lg font-bold text-indigo-700">
          Upload Image Question
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Silahkan upload image jika dibutuhkan
        </p>
      </div>
      <div className="px-4 py-6 mt-4 border border-gray-200 rounded-lg cursor-pointer bg-gray-100 w-full">
        <input
          type="file"
          id="file"
          ref={inputFile}
          className="hidden"
          onChange={handleChange}
        />

        <div onClick={onButtonUpload}>
          <div className="flex items-center justify-center mb-4">
            <HiOutlineUpload size={45} color="#94a3b8" />
          </div>
          <p className="font-sans text-sm text-black text-center">
            Klik disini untuk mengambil gambar
          </p>
          <p className="font-sans text-xs text-gray-400 text-center">
            Format file yang diperbolehkan: .jpg, .jpeg, .png, .svg
          </p>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-6 mt-4">
          {dataImage.length > 0 ? (
            dataImage.map((value, index) => (
              <div key={index} className="sm:col-span-1">
                <figure className="relative max-w-sm transition-all duration-300 cursor-pointer hover:shadow-xl hover:-translate-y-1">
                  <button
                    type="button"
                    onClick={() => deleteImage(value._id)}
                    className="absolute top-0 right-0 bg-gray-500 rounded-full p-1 hover:bg-gray-400"
                  >
                    <HiOutlineX size={12} color="#FFFFFF" />
                  </button>
                  <a
                    href={`${API_UPLOAD}/psisimku/${value.fileName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="rounded-lg"
                      src={`${API_UPLOAD}/psisimku/${value.fileName}`}
                      alt={value.fileName}
                    />
                  </a>
                </figure>
                <div className="font-sans text-xs mt-1 text-gray-600">
                  <input
                    type="text"
                    name="foto"
                    id="foto"
                    value={`${API_UPLOAD}/psisimku/${value.fileName}`}
                    className="bg-gray-100 border border-gray-300 text-gray-900 sm:text-xs rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full px-2 py-1 font-sans"
                    readOnly
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="sm:col-span-6">
              <p className="font-sans text-sm text-gray-400 text-center">
                Tidak ada foto yang di upload
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;
