import React from "react";

import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const Scoring = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/pemohon/${id}`,
    sendRequestGet
  );

  const tableSub = (row, index) => {
    const rowTbl = [];

    rowTbl.push(
      <tr key={index} className="bg-white border-b hover:bg-gray-50">
        <td className="p-2 border-r text-center">{index + 1}</td>
        <td className="px-2 py-2 text-gray-900 font-bold border-r">
          {row.namaScoring}
        </td>
        <td className="px-2 py-2 border-r text-center">{row.totalNilai}</td>
        <td className="px-2 py-2 border-r">{row.namaKategori}</td>
      </tr>
    );

    row.subScoring.map((row2, index2) =>
      rowTbl.push(
        <tr
          key={`sub-${index2}`}
          className="bg-white border-b hover:bg-gray-50"
        >
          <td className="p-2 border-r"></td>
          <td className="px-2 py-2 text-gray-900 border-r">
            {row2.namaScoring}
          </td>
          <td className="px-2 py-2 border-r text-center">{row2.totalNilai}</td>
          <td className="px-2 py-2 border-r">{row2.namaKategori}</td>
        </tr>
      )
    );

    return rowTbl;
  };

  const tableMaster = (row, index) => {
    return (
      <tr key={index} className="bg-white border-b hover:bg-gray-50">
        <td className="p-2 border-r text-center">{index + 1}</td>
        <td className="px-2 py-2 text-gray-900 font-bold border-r">
          {row.namaScoring}
        </td>
        <td className="px-2 py-2 border-r text-center">{row.totalNilai}</td>
        <td className="px-2 py-2 border-r">{row.namaKategori}</td>
      </tr>
    );
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                SCORING
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TOTAL NILAI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                KATEGORI NILAI
              </th>
            </tr>
          </thead>
          <tbody>
            {data.hitungNilai.length > 0 && (
              <>
                {data.hitungNilai.map((row, index) => {
                  return row.subScoring.length > 0
                    ? tableSub(row, index)
                    : tableMaster(row, index);
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Scoring;
