import imgNotFound from "../../assets/images/5274708.jpg";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center">
        <img src={imgNotFound} className="w-80" alt="illustration-error" />
        <div className="text-center">
          <p className="text-4xl text-black font-sans md:text-6xl lg:text-8xl">
            Error!
          </p>
          <p className="text-lg font-sans mb-6 text-gray-500">
            Halaman yang Anda cari tidak ditemukan, silahkan klik tombol dibawah
            ini.
          </p>
        </div>
        <a
          href="/"
          className="py-3 px-6 border rounded-full shadow hover:shadow-lg bg-indigo-600 hover:bg-indigo-400 text-white font-sans"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
