import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPut } from "../../swr";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const FormEdit = () => {
  const { id } = useParams();

  const [namaInstruction, setNamaInstruction] = useState("");
  const [instruction, setInstruction] = useState(() =>
    EditorState.createEmpty()
  );
  const [totalWaktu, setTotalWaktu] = useState(0);

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/instruction/${id}`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/instruction/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setNamaInstruction(data.namaInstruction);
      setTotalWaktu(data.totalWaktu);

      const html = data.instruction;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setInstruction(EditorState.createWithContent(contentState));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();
    try {
      const result = await trigger({
        namaInstruction: namaInstruction,
        instruction: draftToHtml(convertToRaw(instruction.getCurrentContent())),
        totalWaktu: totalWaktu,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Edit Instruksi
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan instruksi soal, isi informasi data dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Nama Petunjuk"
              name="namaInstruction"
              val={namaInstruction}
              set={setNamaInstruction}
              placeholder="Masukkan nama petunjuk"
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-4">
            <div className="border border-slate-100 rounded-lg shadow">
              <Editor
                editorState={instruction}
                onEditorStateChange={setInstruction}
                editorClassName="px-4 py-2 text-sm font-poppins-regular"
              />
            </div>
            <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
              Silahkan isi petunjuk soal
            </p>
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Total Waktu"
              name="totalWaktu"
              val={totalWaktu}
              set={setTotalWaktu}
              placeholder="0"
            />
            <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
              Satuan waktu detik (seconds)
            </p>
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            disabled={isMutating}
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Update Instruksi</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
