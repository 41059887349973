import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { HiOutlineCalendar } from "react-icons/hi";

const JadwalKonseling = () => {
  return (
    <div>
      <Header
        titleHead="Jadwal Konsling"
        icon={<HiOutlineCalendar size={22} color="#1d4ed8" />}
      />
      <Outlet />
    </div>
  );
};

export default JadwalKonseling;
