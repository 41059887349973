export const jensimLabel = (jensim) => {
  let color = "bg-blue-50 text-blue-600";
  switch (jensim) {
    case "Baru":
      color = "bg-blue-50 text-blue-600";
      break;

    case "Perpanjangan":
      color = "bg-orange-50 text-orange-600";
      break;

    case "Peningkatan":
      color = "bg-cyan-50 text-cyan-600";
      break;

    case "Penurunan":
      color = "bg-indigo-50 text-indigo-600";
      break;

    case "Hilang":
      color = "bg-yellow-50 text-yellow-600";
      break;

    case "Rusak":
      color = "bg-red-50 text-red-600";
      break;

    default:
      color = "bg-blue-50 text-blue-600";
      break;
  }

  return <span className={`${color} px-2 py-1 rounded-full`}>{jensim}</span>;
};
