import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

import { API_URL_TES } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlinePrinter, HiOutlineArrowLeft } from "react-icons/hi";

import useSWRMutation from "swr/mutation";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import Select from "../../components/Form2/Select";

const Cetak = () => {
  const [limit, setLimit] = useState([]);
  const [pendidikan, setPendidikan] = useState([]);
  const [katSim, setKatSim] = useState([]);

  const itemPd = [
    {
      name: "Tidak Sekolah",
      value: "-",
    },
    {
      name: "SD",
      value: "SD",
    },
    {
      name: "SLTP/SMP/Sederajat",
      value: "SMP",
    },
    {
      name: "SLTA/SMA/Sederajat",
      value: "SMA",
    },
    {
      name: "Diploma 1",
      value: "D1",
    },
    {
      name: "Diploma 2",
      value: "D2",
    },
    {
      name: "Diploma 3",
      value: "D3",
    },
    {
      name: "Diploma 4",
      value: "D4",
    },
    {
      name: "S1",
      value: "S1",
    },
    {
      name: "S2",
      value: "S2",
    },
    {
      name: "S3",
      value: "S3",
    },
  ];

  const itemLimit = [
    {
      name: "10",
      value: 10,
    },
    {
      name: "50",
      value: 50,
    },
    {
      name: "100",
      value: 100,
    },
  ];

  const itemKatSim = [
    {
      name: "SIM A",
      value: "A",
    },
    {
      name: "SIM B",
      value: "B",
    },
    {
      name: "SIM C",
      value: "C",
    },
  ];

  const navigate = useNavigate();

  const { isLoading } = useSWRMutation(
    `${API_URL_TES}/administrator/rekapsoal?pdTerakhir=${pendidikan}&limit=${limit}&katSim=${katSim}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = async (e) => {
    e.preventDefault();

    navigate(`${pendidikan}/${limit}/${katSim}`);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <div className="sm:col-span-3">
          <div className="sticky top-28">
            <div>
              <h2 className="font-sans text-lg font-bold text-indigo-700">
                Cetak Hasil Tes Pemohon
              </h2>
              <p className="text-sm font-sans text-gray-400">
                Pilih sesuai filter untuk mencetak laporan pemohon!
              </p>
            </div>
            <form onSubmit={searchData}>
              <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Select
                    label="Pendidikan"
                    name="pendidikan"
                    value={pendidikan}
                    set={setPendidikan}
                    item={itemPd}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Limit"
                    name="limit"
                    value={limit}
                    set={setLimit}
                    item={itemLimit}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Kat. Sim"
                    name="katSim"
                    value={katSim}
                    set={setKatSim}
                    item={itemKatSim}
                  />
                </div>
              </div>
              <div className="mt-10 flex items-center gap-x-2">
                <button
                  type="button"
                  className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                  onClick={() => navigate(-1)}
                >
                  <span className="mr-2">
                    <HiOutlineArrowLeft size={18} />
                  </span>
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  onClick={searchData}
                  className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                >
                  <span className="mr-2">
                    <HiOutlinePrinter size={18} />
                  </span>
                  <span>Cetak Laporan</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cetak;
