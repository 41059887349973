import React from "react";
import { Outlet } from "react-router-dom";

import { useParams, NavLink } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const RootPemohon = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/pemohon/${id}`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="fixed bg-white pb-4 w-[calc(100%-16rem)] z-40 shadow-lg top-[94px]">
        <div className="mx-10 pt-4">
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <h2 className="font-sans font-bold text-lg text-black">
                {data.namaLengkap}
              </h2>
              <p className="text-sm font-sans text-gray-400">{data.nik}</p>
            </div>
            <div className="text-center">
              <p className="font-sans text-sm text-gray-400">SIM</p>
              <p className="font-sans text-2xl text-blue-700 font-bold">
                {data.katSim} <span className="text-black">{data.jenSim}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[75px] py-6 overflow-y-auto">
        <div className="text-sm font-sans text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <NavLink
                to="profile"
                className={({ isActive }) =>
                  isActive
                    ? "inline-block p-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg"
                    : "inline-block p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                }
                aria-current="page"
              >
                Profile
              </NavLink>
            </li>
            <li className="mr-2">
              <NavLink
                to="scoring"
                className={({ isActive }) =>
                  isActive
                    ? "inline-block p-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg"
                    : "inline-block p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                }
              >
                Scoring
              </NavLink>
            </li>

            <li className="mr-2">
              <NavLink
                to="docsoal"
                className={({ isActive }) =>
                  isActive
                    ? "inline-block p-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg"
                    : "inline-block p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                }
              >
                Dokumen Soal
              </NavLink>
            </li>
          </ul>
        </div>

        <Outlet />
      </div>
    </div>
  );
};

export default RootPemohon;
