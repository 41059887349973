import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineSave,
  HiOutlineArrowLeft,
  HiOutlinePlus,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestPut, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TextArea from "../../components/Form2/TextArea";
import Select from "../../components/Form2/Select";
import Gallery from "../../components/Gallery";
import Opsi from "../../components/Opsi";
import useSWR from "swr";

const FormEditSoal = () => {
  const { id, idQuestion } = useParams();

  const [noQuestion, setNoQuestion] = useState(0);
  const [tpQuestion, setTpQuestion] = useState("");
  const [txtQuestion, setTxtQuestion] = useState("");
  const [questionOpsi, setQuestionOpsi] = useState([]);
  const [columnOpsi, setColumnOpsi] = useState(0);
  const [scoringId, setScoringId] = useState("");

  const [noOpsi, setNoOpsi] = useState(0);
  const [tpOpsi, setTpOpsi] = useState("");
  const [point, setPoint] = useState(0);
  const [txtOpsi, setTxtOpsi] = useState("");

  const itemTipe = [
    {
      name: "Image",
      value: "image",
    },
    {
      name: "Text",
      value: "text",
    },
    {
      name: "Pola",
      value: "pola",
    },
  ];

  const itemColumn = [
    {
      name: "1 Column",
      value: 1,
    },
    {
      name: "2 Column",
      value: 2,
    },
    {
      name: "3 Column",
      value: 3,
    },
    {
      name: "4 Column",
      value: 4,
    },
  ];

  const navigate = useNavigate();

  const { data, isLoading } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/question/${id}/${idQuestion}`,
    sendRequestGet
  );

  const { data: dataScoring, isLoading: isLoadingScoring } = useSWR(
    `${API_URL_TES}/administrator/scoring/findselect`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/mastersoal/question/${id}/${idQuestion}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setNoQuestion(data.noQuestion);
      setTpQuestion(data.tpQuestion);
      setTxtQuestion(data.txtQuestion);
      setQuestionOpsi(data.questionOpsi);
      setColumnOpsi(data.columnOpsi);
      setScoringId(data.scoringId);

      console.log(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const addOpsi = (e) => {
    e.preventDefault();

    setQuestionOpsi((questionOpsi) => [
      ...questionOpsi,
      {
        noOpsi: noOpsi,
        tpOpsi: tpOpsi,
        point: point,
        txtOpsi: txtOpsi,
      },
    ]);

    setNoOpsi(0);
    setTpOpsi("");
    setPoint(0);
    setTxtOpsi("");
  };

  const delOpsi = (index) => {
    setQuestionOpsi((questionOpsi) => {
      return questionOpsi.filter((_, i) => i !== index);
    });
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      if (questionOpsi.length > 0) {
        const result = await trigger({
          noQuestion: noQuestion,
          tpQuestion: tpQuestion,
          txtQuestion: txtQuestion,
          questionOpsi: questionOpsi,
          columnOpsi: columnOpsi,
          scoringId: scoringId,
        });

        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: result.message,
          confirmButtonText: "Oke",
          willClose: () => {
            navigate(-1);
          },
        });
      } else {
        Swal.fire(
          "Error!",
          "Silahkan tambahkan opsi terlebih dahulu!",
          "warning"
        );
      }
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating || isLoading || isLoadingScoring) return <Loading />;

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <div className="sm:col-span-3">
          <div>
            <h2 className="font-sans text-lg font-bold text-indigo-700">
              Update Question
            </h2>
            <p className="text-sm font-sans text-gray-400">
              Perubahan data question, isi informasi data dibawah ini.
            </p>
          </div>
          <form onSubmit={simpan}>
            <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <InputText
                  label="No. Question"
                  name="noQuestion"
                  val={noQuestion}
                  set={setNoQuestion}
                  placeholder="0"
                />
                <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                  Nomor urut soal akan tampil
                </p>
              </div>

              <div className="sm:col-span-2">
                <Select
                  label="Tipe Question"
                  name="tpQuestion"
                  val={tpQuestion}
                  set={setTpQuestion}
                  item={itemTipe}
                />
                <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                  silahkan pilih tipe dari question
                </p>
              </div>
              <div className="sm:col-span-2">
                <Select
                  label="Column Opsi"
                  name="columnOpsi"
                  val={columnOpsi}
                  set={setColumnOpsi}
                  item={itemColumn}
                />
                <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                  silahkan pilih column untuk opsi
                </p>
              </div>
              <div className="sm:col-start-1 sm:col-span-6">
                <Select
                  label="Scoring"
                  name="scoringId"
                  val={scoringId}
                  set={setScoringId}
                  item={dataScoring}
                />
                <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                  silahkan pilih tipe dari opsi
                </p>
              </div>
              <div className="sm:col-start-1 sm:col-span-6">
                <TextArea
                  label="Txt Question"
                  name="txtQuestion"
                  val={txtQuestion}
                  set={setTxtQuestion}
                  rows={3}
                  placeholder="Masukan url image atau teks pertanyaan?"
                />
                <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                  Untuk url storage foto:
                  https://storage.jurus.id/psisimku/namafile..
                </p>
              </div>
              <div className="sm:col-start-1 sm:col-span-6">
                <h2 className="font-bold font-sans text-black mb-4">
                  Opsi Question
                </h2>

                <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-300">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
                    <div className="sm:col-span-1">
                      <InputText
                        label="No. Opsi"
                        name="noOpsi"
                        val={noOpsi}
                        set={setNoOpsi}
                        placeholder="0"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Select
                        label="Tipe Opsi"
                        name="tpOpsi"
                        val={tpOpsi}
                        set={setTpOpsi}
                        item={itemTipe}
                      />
                      <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                        silahkan pilih tipe dari opsi
                      </p>
                    </div>
                    <div className="sm:col-span-1">
                      <InputText
                        label="Point"
                        name="point"
                        val={point}
                        set={setPoint}
                        placeholder="0"
                      />
                    </div>
                    <div className="sm:col-start-1 sm:col-span-3">
                      <TextArea
                        label="Txt Opsi"
                        name="txtOpsi"
                        val={txtOpsi}
                        set={setTxtOpsi}
                        rows={3}
                        placeholder="Masukan url image atau teks opsi?"
                      />
                    </div>

                    <div className="sm:col-start-1 sm:col-span-3">
                      <button
                        type="button"
                        onClick={addOpsi}
                        className="text-white mt-2 bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                      >
                        <span className="mr-2">
                          <HiOutlinePlus size={18} />
                        </span>
                        <span>Tambah Opsi</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:col-start-1 sm:col-span-6">
                {questionOpsi.length > 0 ? (
                  questionOpsi.map((item, index) => (
                    <Opsi
                      key={`opsi-${index}`}
                      noOpsi={item.noOpsi}
                      tpOpsi={item.tpOpsi}
                      point={item.point}
                      txtOpsi={item.txtOpsi}
                      onClick={() => delOpsi(index)}
                    />
                  ))
                ) : (
                  <p className="text-sm font-sans text-gray-400 text-center">
                    Silah tambahkan opsi...
                  </p>
                )}
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-2">
              <button
                type="button"
                className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                onClick={() => navigate(-1)}
              >
                <span className="mr-2">
                  <HiOutlineArrowLeft size={18} />
                </span>
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                disabled={isMutating}
                className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <HiOutlineSave size={18} />
                </span>
                <span>Update Question</span>
              </button>
            </div>
          </form>
        </div>
        <div className="sm:col-span-2">
          <Gallery mastersoalId={id} />
        </div>
      </div>
    </div>
  );
};

export default FormEditSoal;
