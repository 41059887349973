import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { HiOutlineUsers } from "react-icons/hi";

const User = () => {
  return (
    <div>
      <Header
        titleHead="User"
        icon={<HiOutlineUsers size={22} color="#1d4ed8" />}
      />
      <Outlet />
    </div>
  );
};

export default User;
