import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineSave,
  HiOutlineArrowLeft,
  HiOutlinePlus,
  HiX,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPut } from "../../swr";
import Loading from "../Loading";
import TextArea from "../../components/Form2/TextArea";
import Select from "../../components/Form2/Select";
import MasterSoal from "../../components/MasterSoal";
import MasterSoal2 from "../../components/MasterSoal2";

const FormEdit = () => {
  const { id } = useParams();
  const [katSim, setKatSim] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [noUrut, setNoUrut] = useState(0);
  const [pendidikan, setPendidikan] = useState([]);
  const [mastersoals, setMasterSoals] = useState([]);
  const [mastersoalId, setMastersoalId] = useState("");
  const [instructionId, setInstructionId] = useState("");
  const [stSoal, setStSoal] = useState("N");
  const [totalGagal, setTotalGagal] = useState(0);

  const [pddk, setPddk] = useState("");

  const itemTipe = [
    {
      name: "SIM A",
      value: "A",
    },
    {
      name: "SIM B",
      value: "B",
    },
    {
      name: "SIM C",
      value: "C",
    },
    {
      name: "SIM D",
      value: "D",
    },
    {
      name: "SIM A dan C",
      value: "AC",
    },
    {
      name: "SIM B dan C",
      value: "BC",
    },
  ];

  const itemPd = [
    {
      name: "Tidak Sekolah",
      value: "-",
    },
    {
      name: "SD",
      value: "SD",
    },
    {
      name: "SLTP/SMP/Sederajat",
      value: "SMP",
    },
    {
      name: "SLTA/SMA/Sederajat",
      value: "SMA",
    },
    {
      name: "Diploma 1",
      value: "D1",
    },
    {
      name: "Diploma 2",
      value: "D2",
    },
    {
      name: "Diploma 3",
      value: "D3",
    },
    {
      name: "Diploma 4",
      value: "D4",
    },
    {
      name: "S1",
      value: "S1",
    },
    {
      name: "S2",
      value: "S2",
    },
    {
      name: "S3",
      value: "S3",
    },
  ];

  const itemStSoal = [
    {
      name: "Start",
      value: "Y",
    },
    {
      name: "Stop",
      value: "N",
    },
  ];

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/soalactive/${id}`,
    sendRequestPut
  );

  const { data: allMaster, isLoading: isLoadingAllMaster } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/all`,
    sendRequestGet
  );

  const { data: allInstructions, isLoading: isLoadingAllInstruction } = useSWR(
    `${API_URL_TES}/administrator/instruction-select`,
    sendRequestGet
  );

  const { data, isLoading } = useSWR(
    `${API_URL_TES}/administrator/soalactive/${id}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setKatSim(data.katSim);
      setKeterangan(data.keterangan);
      setPendidikan(data.pendidikan);
      setTotalGagal(data.totalGagal);
      setMasterSoals(data.mastersoals);
      setStSoal(data.stSoal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const addPendidikan = (e) => {
    e.preventDefault();

    if (pddk !== "") {
      if (pendidikan.length > 0) {
        const pddkCek = pendidikan.find((item) => item === pddk);
        if (!pddkCek) {
          setPendidikan((pendidikan) => [...pendidikan, pddk]);
        } else {
          Swal.fire("Error!", "Pendidikan sudah pernah di pilih!", "warning");
        }
      } else {
        setPendidikan((pendidikan) => [...pendidikan, pddk]);
      }
    }

    setPddk("");
  };

  const delPendidikan = (index) => {
    setPendidikan((pendidikan) => {
      return pendidikan.filter((_, i) => i !== index);
    });
  };

  const addMasterSoal = (e) => {
    e.preventDefault();

    if (mastersoalId !== "" && noUrut !== 0 && instructionId !== "") {
      setMasterSoals((mastersoals) => [
        ...mastersoals,
        {
          mastersoalId: mastersoalId,
          instructionId: instructionId,
          noUrut: noUrut,
        },
      ]);

      setMastersoalId("");
      setNoUrut(0);
    } else if (mastersoalId !== "" && noUrut !== 0 && instructionId === "") {
      setMasterSoals((mastersoals) => [
        ...mastersoals,
        {
          mastersoalId: mastersoalId,
          noUrut: noUrut,
        },
      ]);

      setMastersoalId("");
      setNoUrut(0);
    } else {
      Swal.fire(
        "Error!",
        "Master soal harus di isi atau nomor urut belum di isi!",
        "warning"
      );
    }
  };

  const delMasterSoal = (index) => {
    setMasterSoals((mastersoals) => {
      return mastersoals.filter((_, i) => i !== index);
    });
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      if (mastersoals.length > 0 || pendidikan.length > 0) {
        const result = await trigger({
          katSim: katSim,
          keterangan: keterangan,
          pendidikan: pendidikan,
          totalGagal: totalGagal,
          mastersoals: mastersoals,
          stSoal: stSoal,
        });

        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: result.message,
          confirmButtonText: "Oke",
          willClose: () => {
            navigate(-1);
          },
        });
      } else {
        Swal.fire(
          "Error!",
          "Silahkan tambahkan master soal terlebih dahulu/pendidikan belum ada yang dipilih!",
          "warning"
        );
      }
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating || isLoadingAllMaster || isLoading || isLoadingAllInstruction)
    return <Loading />;

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <div className="sm:col-span-3">
          <div className="sticky top-28">
            <div>
              <h2 className="font-sans text-lg font-bold text-indigo-700">
                Tambah Soal Aktif
              </h2>
              <p className="text-sm font-sans text-gray-400">
                Penambahan data outlet, isi informasi data dibawah ini.
              </p>
            </div>
            <form onSubmit={simpan}>
              <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-start-1 sm:col-span-6">
                  <Select
                    label="Kategori SIM"
                    name="katSim"
                    val={katSim}
                    set={setKatSim}
                    item={itemTipe}
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    silahkan kategori sim untuk soal aktif
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-3">
                  <InputText
                    label="< Total Gagal"
                    name="totalGagal"
                    val={totalGagal}
                    set={setTotalGagal}
                    placeholder="0"
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    nilai gagal/dibawah batas minimum lebih kecil dari...
                  </p>
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Status"
                    name="stSoal"
                    val={stSoal}
                    set={setStSoal}
                    item={itemStSoal}
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    silahkan kategori sim untuk soal aktif
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <TextArea
                    label="Keterangan"
                    name="keterangan"
                    val={keterangan}
                    set={setKeterangan}
                    rows={3}
                    placeholder="Silahkan isi keterangan..."
                  />
                  <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                    Keterangan untuk soal aktif
                  </p>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <h2 className="font-bold font-sans text-black mb-4">
                    Jenjang Pendidikan
                  </h2>

                  <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-300">
                    <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
                      <div className="sm:col-span-3 col-span-1">
                        <Select
                          label="Pendidikan"
                          name="pddk"
                          val={pddk}
                          set={setPddk}
                          item={itemPd}
                        />
                        <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                          silahkan pilih item pendidikan untuk tes
                        </p>
                      </div>

                      <div className="sm:col-start-1 sm:col-span-3">
                        <button
                          type="button"
                          onClick={addPendidikan}
                          className="text-white mt-2 bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                        >
                          <span className="mr-2">
                            <HiOutlinePlus size={18} />
                          </span>
                          <span>Tambah Jenjang Pendidikan</span>
                        </button>
                      </div>
                      <div className="sm:col-start-1 sm:col-span-3">
                        {pendidikan.length > 0 ? (
                          <div className="flex flex-wrap items-center">
                            {pendidikan.map((pd, index) => (
                              <div
                                key={`pd-${index}`}
                                className="flex flex-row item-center mt-1 px-4 py-1 rounded text-sm mr-2 shadow-sm border border-blue-100"
                              >
                                <span className="flex-1 mr-2">{pd}</span>
                                <button
                                  type="button"
                                  onClick={() => delPendidikan(index)}
                                  className="text-gray-400"
                                >
                                  <HiX size={12} />
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="sm:col-span-6">
                            <p className="font-sans text-sm text-red-400 text-center mt-4">
                              Tidak ada jenjang pendidikan yang ditambahkan...
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-start-1 sm:col-span-6">
                  <h2 className="font-bold font-sans text-black mb-4">
                    Master Soal Tes
                  </h2>

                  <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-300">
                    <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
                      <div className="sm:col-start-1 sm:col-span-1">
                        <InputText
                          label="Nomor Urut"
                          name="noUrut"
                          val={noUrut}
                          set={setNoUrut}
                          placeholder="0"
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <Select
                          label="Master Soal"
                          name="mastersoalId"
                          val={mastersoalId}
                          set={setMastersoalId}
                          item={allMaster}
                        />
                        <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                          silahkan pilih tipe dari opsi
                        </p>
                      </div>
                      <div className="sm:col-start-1 sm:col-span-2">
                        <Select
                          label="Petunjuk Soal"
                          name="instructionId"
                          val={instructionId}
                          set={setInstructionId}
                          item={allInstructions}
                        />
                        <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
                          silahkan pilih petunjuk soal, jika tidak ada petunjuk
                          tidak perlu dipilih
                        </p>
                      </div>
                      <div className="sm:col-start-1 sm:col-span-3">
                        <button
                          type="button"
                          onClick={addMasterSoal}
                          className="text-white mt-2 bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50 focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                        >
                          <span className="mr-2">
                            <HiOutlinePlus size={18} />
                          </span>
                          <span>Tambah Master Soal</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 flex items-center gap-x-2">
                <button
                  type="button"
                  className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                  onClick={() => navigate(-1)}
                >
                  <span className="mr-2">
                    <HiOutlineArrowLeft size={18} />
                  </span>
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  disabled={isMutating}
                  className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50 focus:outline-none focus:ring-[#3b5998]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                >
                  <span className="mr-2">
                    <HiOutlineSave size={18} />
                  </span>
                  <span>Update Soal Aktif</span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div>
            <h2 className="font-sans text-lg font-bold text-indigo-700">
              Master Soal
            </h2>
            <p className="text-sm font-sans text-gray-400">
              Master soal yang sudah di pilih
            </p>
          </div>

          {mastersoals.length > 0 ? (
            mastersoals.map((item, index) => (
              <div key={`mstsoal-${index}`}>
                {item.instructionId ? (
                  <MasterSoal
                    mastersoalId={item.mastersoalId}
                    instructionId={item.instructionId}
                    noUrut={item.noUrut}
                    onClick={() => delMasterSoal(index)}
                  />
                ) : (
                  <MasterSoal2
                    mastersoalId={item.mastersoalId}
                    noUrut={item.noUrut}
                    onClick={() => delMasterSoal(index)}
                  />
                )}
              </div>
            ))
          ) : (
            <div className="sm:col-span-6">
              <p className="font-sans text-sm text-red-400 text-center mt-4">
                Tidak master soal yang ditambahkan...
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormEdit;
